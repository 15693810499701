import React from 'react';

import { formColor } from '../../../helper';
import { InfoRowHeightContext } from '../InfoRowHeightContext';

import NavLink from './NavLink';
import * as styles from './styles.module.css';

class NavigationLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      showSubPageLink: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMenuLinkClick = this.handleMenuLinkClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.matches !== this.props.matches) {
      this.handleChange(false, this.props.matches);
    }
  }

  handleChange(open, mtchs) {
    const matches = mtchs || this.props.matches;
    const nav = document.getElementById('NavigationBarAnchor');

    if (!open || matches) {
      document.documentElement.style.removeProperty('overflow');
      nav.style.removeProperty('position');
      nav.style.removeProperty('top');
      nav.style.removeProperty('bottom');
    } else if (!matches) {
      const pos = window.getComputedStyle(nav, null).position;

      if (nav.className.includes('sectionNavbar') && pos !== 'absolute') {
        nav.style.position = 'absolute';
        nav.style.top = 0;
        nav.style.bottom = 0;
        document.documentElement.style.overflow = 'hidden';
      } else if (nav.className.includes('sectionNavbarOverlay')) {
        nav.style.bottom = 0;
        nav.style.top = 0;
      }
    }

    this.setState({
      open,
    });
  }

  handleMenuLinkClick(event) {
    this.setState({
      showSubPageLink: this.state.showSubPageLink === event ? null : event,
    });
  }

  render() {
    if (!this.props.links) return null;

    const { header } = this.props;
    let elements = [];
    const burger = [];
    const isBurger =
      !this.props.secondary &&
      (
        this.props.links.length > this.props.burgerBreak ||
        (!this.props.matches && this.props.links.length > 0) ||
        this.props.wrapped
      );

    const left = [];
    const right = [];

    let color = this.props.color0;
    if (this.props.linkColor) {
      color = formColor(
        { solid: this.props.linkColor.color },
        false,
        this.props.linkColor.opacity,
        undefined,
        this.props.themeData.colors,
        header && header.type === 'invert',
      ).backgroundColor;
    }

    let activeStyle;
    switch (this.props.linkStyle) {
      case 1:
        activeStyle = {
          color,
          fontWeight: 'bold',
        };
        break;
      case 2:
        activeStyle = {
          color,
          borderBottom: `1px solid ${color}`,
        };
        break;
      case 3:
        activeStyle = {
          borderBottom: `1px solid ${color}`,
        };
        break;
      default:
      // Nothing here
    }

    this.props.links.forEach((link, index) => {
      const result = (
        <NavLink
          link={link}
          color={this.props.color}
          activeStyle={activeStyle}
          divider={!isBurger && index < this.props.links.length - 1 ? this.props.divider : undefined}
          themeData={this.props.themeData}
          onLinkClick={() => this.handleChange(false)}
          onMenuLinkClick={this.handleMenuLinkClick}
          key={`NavLink_${link.text}_${index}`}
          showSubPageLink={this.state.showSubPageLink}
          images={this.props.images}
          isBurger={isBurger}
          buttons={this.props.buttons}
          subColor={this.props.burgerColor}
          additionalDivider={this.props.layout === 'Divided' && index === Math.floor(this.props.links.length / 2)}
          header={header}
        />
      );

      if (isBurger) {
        if (index === 0 && this.state.open === true && this.props.matches === false) {
          const test = (
            <div key="BurgerCloseBtn" className={styles.btnWrapper}>
              <button className={styles.cancelBtn} onClick={() => this.handleChange(!this.state.open)}>
                <i style={{ color: this.props.color }} className={`entypo icon-cancel ${styles.icon}`} />
              </button>
            </div>
          );
          burger.push(test);
        }

        burger.push(result);
      }

      if (isBurger !== true) {
        if (this.props.layout === 'Divided') {
          if (index >= Math.floor(this.props.links.length / 2)) {
            right.push(result);
          } else {
            left.push(result);
          }
        } else {
          elements.push(result);
        }
      }
    });

    if (isBurger !== true && !this.props.secondary && this.props.layout === 'Divided' && this.props.links.length > 0) {
      elements.push(
        <div key="navigation_divided_left" className={styles.navbarDividedLeft}>
          {left}
        </div>,
      );
      elements.push(<React.Fragment key="navigation_divided_center">{this.props.lg}</React.Fragment>);
      elements.push(
        <div key="navigation_divided_right" className={styles.navbarDividedRight}>
          {right}
        </div>,
      );
    }

    if (isBurger === true) {
      let style = this.props.burgerColor;
      if (this.state.open === true && this.props.matches === false) {
        const wrapper = document.getElementById('burgerWrapper');
        if (wrapper) {
          const rect = wrapper.getBoundingClientRect();
          const scrollPos = document.documentElement.scrollTop;
          const infoHeight = this.context;
          let height = '100vh';
          if (this.props.layout !== 'Center' && scrollPos > 0) height = 'calc(100vh + 40px)';

          style = {
            ...this.props.burgerColor,
            height,
            top: `${scrollPos - (infoHeight + rect.height + 13)}px`,
          };
        }
      }

      const margin = '20px 0 60px 0';

      elements = (
        <div
          id="burgerWrapper"
          className={`${
            styles[`burgerToggle${this.state.open === true && this.props.matches === false ? 'Open' : ''}`]
          }`}
        >
          <input
            type="checkbox"
            value={this.state.open}
            defaultChecked={false}
            onClick={
              this.state.open === true && this.props.matches === false
                ? undefined
                : () => this.handleChange(!this.state.open)
            }
            disabled={this.state.open === true && this.props.matches === false ? 'disabled' : false}
            className={styles.burgerInput}
          />
          <span className={styles.burgerLine} style={{ background: this.props.color }} />
          <span className={styles.burgerLine} style={{ background: this.props.color }} />
          <span className={styles.burgerLine} style={{ background: this.props.color }} />
          <ul className={styles[`burgerMenu${this.props.layout}`]} style={style}>
            <div style={{ margin }}>
              {burger}
              {!this.props.matches && this.props.secondaryNav !== undefined && (
                <div style={{ marginTop: '8px' }}>{this.props.secondaryNav}</div>
              )}
            </div>
          </ul>
        </div>
      );
    }

    return (
      <nav
        id={['Center', 'Divided'].includes(this.props.layout) ? `nav${this.props.layout}` : undefined}
        className={!this.props.secondary ? styles[this.props.menuStyle] : styles.secondary}
        style={isBurger ? { minWidth: '33px' } : undefined}
      >
        {elements}
      </nav>
    );
  }
}

NavigationLinks.contextType = InfoRowHeightContext;

export default NavigationLinks;
