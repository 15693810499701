// extracted by mini-css-extract-plugin
export var lbContainerOuter = "s_sm";
export var lbContainerInner = "s_sn";
export var movingForwards = "s_sp";
export var movingForwardsOther = "s_sq";
export var movingBackwards = "s_sr";
export var movingBackwardsOther = "s_ss";
export var lbImage = "s_st";
export var lbOtherImage = "s_sv";
export var prevButton = "s_sw";
export var nextButton = "s_sx";
export var closing = "s_sy";
export var appear = "s_sz";