// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "p_qD d_bz";
export var storyRowWrapper = "p_hv d_hv d_bG";
export var storyLeftWrapper = "p_qF d_bw d_bL";
export var storyWrapperFull = "p_qG d_cz";
export var storyWrapperFullLeft = "p_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "p_mC d_hw";
export var storyLeftWrapperCenter = "p_qH d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "p_qJ d_hC";
export var storyHeader = "p_qK d_hB d_s d_cp";
export var storyHeaderCenter = "p_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "p_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "p_qL d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "p_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "p_qM d_fc d_W";
export var imageWrapperFull = "p_qN d_s d_D d_bb d_W";